<template>
  <div class="compare_block" >
    <div style="width: 20%;" class="left_block">
      <div class="title_block">
        <p>活动对比</p>
      </div>
      <div class="info_block">
        范围： <el-select
          v-model="ta"
          placeholder="请选择"
          size="mini"
          class="autoWidth"
      >
        <template #prefix>
          {{ formatCrowdOptions().filter(i => i.value === ta)[0].label}}
        </template>
        <el-option
            v-for="item in formatCrowdOptions()"
            :key="item.value"
            :label="item.label"
            :value="item.value"
        >
        </el-option>
      </el-select>
      </div>
      <div
          v-for="(campaign, index) in campaignList"
          :key="campaign.campaignId"
          class="campaign_contain"
      >
        <el-card class="campaign_baseInfo">
          <p>{{ campaign.brand }}</p>
          <p>{{ campaign.campaignName }}</p>
          <p class="line"></p>
          <div>
            <svg-icon
                v-for="platform in campaign.platform"
                :key="platform"
                :class-name="platform"
                style="margin-right: 10px; font-size: 20px"
                :style="{ fill: fillColor(platform) }"
            />
          </div>
          <p class="base_info_item">
            <i class="el-icon-date"></i>
            {{ campaign.duration.replace(",", " 至 ") }}
          </p>
          <p class="base_info_item">
            <i class="el-icon-user-solid"></i>
            KOL数量：{{ campaign.kolNum }}
          </p>
          <p class="base_info_item">
            <i class="el-icon-document"></i>
            文章数量：{{ campaign.postNum }}
          </p>
          <p class="base_info_item">
            <i class="el-icon-shopping-cart-1"></i>
            总费用：￥{{ sliceDecimal(campaign.totalCost / 100) }}
          </p>
        </el-card>
      </div>
    </div>

    <div style="width: 80%;">

      <div
          v-for="(campaign, index) in campaignList"
          :key="campaign.campaignId"
          class="campaign_contain"
      >
        <el-card class="campaign_data">
          <div class="title_block">
            <p>{{campaign.campaignName}}</p>
            <el-radio-group
                v-model="campaigns[index].platform"
                size="mini"
            >
              <el-radio-button
                  v-for="item in formatPlatform(campaign.platform)"
                  :key="item.value"
                  :label="item.value"
              >
                {{ item.label }}
              </el-radio-button>
            </el-radio-group>
          </div>
          <div style="margin-bottom: 20px; display: flex; align-items: center">
            <el-select
                v-model="campaigns[index].agency"
                placeholder="请选择"
                size="mini"
                class="autoWidth"
            >
              <template #prefix>
                {{ formatSupplier(campaign.agency).filter(i => i.value === campaigns[index].agency)[0].label}}
              </template>
              <el-option
                  v-for="item in formatSupplier(campaign.agency)"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="date_contain">
            <div class="date_item">
              <p class="date_value">
                {{ sliceDecimal(campaign.index.impression) }}
              </p>
              <p class="date_title">曝光数</p>
            </div>
            <div class="date_item">
              <p class="date_value">
                {{ sliceDecimal(campaign.index.engagement) }}
              </p>
              <p class="date_title">互动数</p>

            </div>
            <div class="date_item">
              <p class="date_value">
                {{
                  campaign.index.engagementRate
                      ? campaign.index.engagementRate
                      : "N/A"
                }}
              </p>
              <p class="date_title">互动率</p>

            </div>
            <div class="date_item">
              <p class="date_value">
                {{ campaign.index.cpi ? campaign.index.cpi : "N/A" }}
              </p>
              <p class="date_title">CPI</p>

            </div>
            <div class="date_item">
              <p class="date_value">
                {{ campaign.index.cpe ? campaign.index.cpe : "N/A" }}
              </p>
              <p class="date_title">CPE</p>

            </div>
            <div class="date_item">
              <p class="date_value">
                {{ campaign.index.cpta ? campaign.index.cpta : "N/A" }}
              </p>
              <p class="date_title">CPTA</p>

            </div>
          </div>
        </el-card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { getCompareList } from "@/api/campaignTrace";

export default {
  name: "index",
  data() {
    return {
      campaignList: [],
      ta: "32_1",
      campaigns: [{ id: "", platform: "all", agency: "all" }],
    };
  },
  props: ["campaignArr"],
  computed: {
    ...mapGetters(["crowdList", "categorySeg"]),
    params() {
      return {
        ta: this.ta,
        campaigns: this.campaigns,
      };
    },
  },
  async mounted() {
    this.campaigns = this.campaignArr.map((item) => {
      return {
        id: item * 1,
        platform: "all",
        agency: "all",
      };
    });
  },
  methods: {
    fillColor(platform) {
      switch (platform) {
        case "wechat":
          return "#04BE02";
        case "weibo":
          return "#FF9933";
        case "douyin":
          return "#260e1f";
        case "xiaohongshu":
          return "#eb414c";
        case "bilibili":
          return "#fb7299";
      }
    },
    formatCrowdOptions() {
      return this.crowdList.map((v) => {
        return {
          value: v.key,
          label: v.name,
        };
      });
    },
    formatPlatform(platArr) {
      const res = platArr.map((item) => {
        return {
          label: this.platformToZn(item),
          value: item,
        };
      });
      res.unshift({ label: "全平台", value: "all" });
      return res;
    },
    formatSupplier(agencyArr) {
      const res = agencyArr
          ? agencyArr.map((item) => {
            return {
              label: item,
              value: item,
            };
          })
          : [];
      res.unshift({ label: "全部供应商", value: "all" });
      return res;
    },
    async getList() {
      const { data } = await getCompareList(this.params);
      this.campaignList = data;
    },
  },
  watch: {
    params: {
      deep: true,
      handler() {
        this.getList();
      },
    },
  },
};
</script>

<style scoped lang="scss">
.compare_block{
  width: 100%;
  display: flex;justify-content: space-between;gap:20px;height:100%;
  .left_block{
    background: #fff;
    border-radius: 20px;
    height:100%;
    .title_block{
      display: flex;
      justify-content: space-between;
      align-items: center;
      height:67px;
      border-bottom:1px solid #EDEDED;
      padding:0 20px;
      p{
        font-weight: 500;
        font-size:20px;
        display: flex;
        align-items: center;
      }
    }
    .info_block{
      padding:0 20px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap:10px;
      .logo_block{
        width:35%;
        aspect-ratio: 1/1;
        box-shadow: 0px 0px 8.4px 0px #0000001F;
        img{
          width: 100%;
          height:100%;
        }
      }
      p{
        font-family: PingFang SC;
        font-size: 16px;
        font-weight: 500;
        line-height: 22.4px;
      }
    }
    .date_block{
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding:20px;
      span{
      }
    }
  }
}
.campaign_contain {
  margin: 0 20px 20px;
  display: flex;

  .campaign_baseInfo {
    width: 100%;
    box-shadow: none;
    border:0;
    background: #f9f9f9;
    div,
    p {
      height: 30px;
      line-height: 30px;
    }

    .line {
      height: 1px;
      border-top: 1px solid #ddd;
      margin: 10px 0;
    }

    .base_info_item {
      color: #aaa;
    }
  }

  .campaign_data {
    flex: 1;
    box-shadow: none;
    border-radius: 20px;
    .title_block{
      display: flex;
      justify-content: space-between;
      align-items: center;
      p{
        font-family: PingFang SC;
        font-size: 20px;
        font-weight: bold;
        line-height: 28px;
        color:#222;
      }
    }
    .date_contain {
      display: flex;
      flex-wrap: wrap;
      background: #f9f9f9;
      border-right: 10px;
      align-items: center;
      min-height:130px;
      .date_item {
        flex: 0 0 16%;
        .date_title {
          color: #666;
          text-align: center;
          line-height: 26px;
          font-size:16px;
          margin-top:10px;
        }

        .date_value {
          font-weight: bold;
          line-height: 30px;
          font-size:24px;
          text-align: center;
        }
      }
    }
  }
}
</style>
<style lang="scss">
.autoWidth {
  min-width: 50px;   //设置最小的宽度
  text-align: start;  // 这里是个坑，由于我是在vue中挂载的页面，如果给app的样式设置了text-align：center后，这里是需要重新设置start 否则选择框内的内容是默认居中，会造成未选择内容和选择内容时样式不对
}
.autoWidth .el-select__prefix {  // 这里直接时复制原来的input的样式，给.el-input__prefix加上
  position: relative;
  box-sizing: border-box;
  padding: 0;
  height: 40px;
  line-height: 40px;
  color: #222;
  left: 0;
  cursor: pointer;
}
.autoWidth .el-select__selection {
  position: absolute;   //这里一定需要给，给内容进行定位
  display: none;
}
.autoWidth .el-select__wrapper{
  box-shadow: none;
  padding:0;
}
.autoWidth .el-select__wrapper:hover{
  border:0;
  box-shadow: none;
}
.autoWidth .el-input .el-select__caret{
  width:auto;
}
.autoWidth .el-select__suffix{
  right:0;
}
</style>
